<script lang="ts" setup>
import { getOverallPerformance } from '@/api/reports.api';
import { organisationStore } from '@/stores/organisation';
import { Skeleton, SkeletonLoader } from '@dailycare-au/ui'
import { chartOptions, registerChartComponents } from '@dailycare-au/visualise';
import { computedAsync } from '@vueuse/core';
import { CategoryScale, Chart, LinearScale, LineElement, PointElement } from 'chart.js';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { Line } from 'vue-chartjs'
import { useReportFilters } from './filters';

registerChartComponents(CategoryScale, LineElement, PointElement, LinearScale)

const { period } = useReportFilters()
const { organisation } = storeToRefs(organisationStore())

const loading = ref(false)
const data = computedAsync(async () => {
  const { data } = await getOverallPerformance(organisation.value!.id, period.value, 'this')
  return data
}, null, { evaluating: loading })


const options = chartOptions('line', {
  scales: {
    y: { ticks: { callback: (val, index, ticks) => `${val}%` } },
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: ({ dataset, raw }) => `${dataset.label}: ${raw}%`
      }
    }
  }
})
const mappedData = computed(() => {
  if (!data.value) return { labels: [], datasets: [] }

  return {
    labels: data.value.scales.find(({ type }) => type === 'x')?.labels ?? [],
    datasets: data.value.datasets
  }
})
</script>

<template>
  <div class="h-[500px] w-full col-span-full mt-16">
    <SkeletonLoader :loading="loading">
      <Line :data="mappedData" :options="options" class="size-full" />

      <template #skeleton>
        <Skeleton class="size-full" />
      </template>
    </SkeletonLoader>
  </div>
</template>