<script lang="ts" setup>
import { Dial } from '@dailycare-au/visualise'
import { SkeletonLoader, Skeleton, TooltipProvider } from '@dailycare-au/ui'
import { asyncComputed } from '@vueuse/core'
import { ref } from 'vue'
import { organisationStore } from '@/stores/organisation'
import { storeToRefs } from 'pinia'
import { getReportOverview } from '@/api/reports.api'
import { useReportFilters } from './filters'

const { organisation } = storeToRefs(organisationStore())
const { period } = useReportFilters()

const loading = ref(false)
const overview = asyncComputed(async () => {
  const { data } = await getReportOverview(organisation.value!.id, period.value, 'this')
  return data
}, [], { evaluating: loading })
</script>

<template>
  <TooltipProvider>
    <SkeletonLoader :count="5" :loading="loading">
      <Dial
        v-for="item in overview"
        :key="item.label"
        :value="item.value"
        :title="item.label"
        :description="item.description"
        :prev="item.previous_value"
        :value-suffix="item.suffix"
      />

      <template #skeleton>
        <Skeleton class="h-40 w-full rounded-xl" />
      </template>
    </SkeletonLoader>
  </TooltipProvider>
</template>