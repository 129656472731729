import client from './client'

const baseURL = '/access/reports'

export type Period = '7day' | '30day' | '90day' | '365day'
export type PeriodType = 'this' | 'last'

export interface Score {
  label:          string;
  description?:   string;
  value:          number;
  previous_value: number;
  suffix?:        string;
}

export interface XYConfig {
  title:    string;
  scales:   Scale[];
  datasets: Dataset[];
}

export interface Dataset {
  label: string;
  data:  number[];
}

export interface Scale {
  type:   'x' | 'y';
  title?: string;
  labels?: string[];
}

export interface FacilityByHospital extends OrganisationByHospital {
  listingid: number;
  listingname: string;
}

export interface OrganisationByHospital {
  healthserviceid: number;
  healthservicename: string;
  hospitalid: number;
  hospitalname: string;
  referrals: number;
  referralsshare: string;
}


export interface ListingPerformance {
  listing_id:                number;
  listing_name:              string;
  avg_vacancy_update:        number;
  vacancy_updated:           number;
  page_one_appearances:      number;
  page_one_appearances_rank: number;
  referrals:                 number;
  referrals_rank:            number;
}

export interface UserPerformance {
  user_id:        number;
  user_name:      string;
  user_job_title: string;
  user_email:     string;
  user_status:    string;
  listing_count?:  number;
  last_login?:     Date;
  sessions_sum?:   number;
  sessions_days?:  number;
}


export function getReportOverview(orgId: string | number, period: Period = '7day', periodType: PeriodType = 'this') {
  const url = `${baseURL}/organisations/${orgId}/overview/${periodType}/${period}`
  return client.get<Score[]>(url)
}

export function getOverallPerformance(orgId: string | number, period: Period = '7day', periodType: PeriodType = 'this') {
  const url = `${baseURL}/organisations/${orgId}/performance/${periodType}/${period}`
  return client.get<XYConfig>(url)
}
export function getOverallPerformanceByHospital(orgId: string | number, period: Period = '7day', periodType: PeriodType = 'this') {
  const url = `${baseURL}/organisations/${orgId}/hospital/performance/${periodType}/${period}`
  return client.get<OrganisationByHospital[]>(url)
}

export function getFacilityPerformanceByHospital(period: Period = '7day', periodType: PeriodType = 'this') {
  const url = `${baseURL}/facilities/hospital/performance/${periodType}/${period}`
  return client.get<FacilityByHospital[]>(url)
}

export function getListingPerformance(period: Period = '7day', periodType: PeriodType = 'this') {
  const url = `${baseURL}/facilities/performance/${periodType}/${period}`
  return client.get<ListingPerformance[]>(url)
}

export function getUserPerformance(orgId: string | number, period: Period = '7day', periodType: PeriodType = 'this') {
  const url = `${baseURL}/organisations/${orgId}/user/performance/${periodType}/${period}`
  return client.get<UserPerformance[]>(url)
}