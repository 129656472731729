<script lang="ts" setup>
import { createColumnHelper } from '@tanstack/vue-table'
import { Datatable } from '@dailycare-au/visualise';
import { asyncComputed } from '@vueuse/core'
import { ref } from 'vue'
import { organisationStore } from '@/stores/organisation'
import { storeToRefs } from 'pinia'
import { getOverallPerformanceByHospital, OrganisationByHospital } from '@/api/reports.api'
import { useReportFilters } from './filters'


const helper = createColumnHelper<OrganisationByHospital>()
const columns = [
  helper.accessor('healthservicename', { header: 'Health service' }),
  helper.accessor('hospitalname', { header: 'Hospital' }),
  helper.accessor('referrals', { header: 'Patient shortlists' }),
  helper.accessor('referralsshare', { header: 'Share of patient shortlists', sortingFn: 'alphanumeric'}),
]

const { period } = useReportFilters()
const { organisation } = storeToRefs(organisationStore())

const loading = ref(false)
const data = asyncComputed(async () => {
  const { data } = await getOverallPerformanceByHospital(organisation.value!.id, period.value, 'this')
  return data
}, [], { evaluating: loading })
</script>

<template>
  <div class="w-full col-span-full mt-16">
    <h3 class="subtitle is-3">Organisation performance by hospital</h3>
    <Datatable :columns="columns" :data="data" />
  </div>
</template>