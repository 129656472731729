<script lang="ts">

</script>

<script lang="ts" setup>
import { ref } from 'vue';
import { useUserReportFilters } from './filters';
import { computedAsync } from '@vueuse/core';
import { getUserPerformance } from '@/api/reports.api';
import { Datatable } from '@dailycare-au/visualise';
import type { UserPerformance } from '@/api/reports.api';
import { createColumnHelper } from '@tanstack/vue-table';
import { storeToRefs } from 'pinia';
import { organisationStore } from '@/stores/organisation';
import { format } from 'date-fns';

const { period } = useUserReportFilters()
const { organisation } = storeToRefs(organisationStore())

const loading = ref(false)
const data = computedAsync(async () => {
  const { data } = await getUserPerformance(organisation.value!.id, period.value, 'this')
  return data
}, [], { evaluating: loading })

const helper = createColumnHelper<UserPerformance>()
const columns = [
  helper.accessor('user_name', { header: 'Name' }),
  helper.accessor('user_job_title', { header: 'Job title' }),
  helper.accessor('user_email', { header: 'Email' }),
  helper.accessor('listing_count', { header: 'Listings'}),
  helper.accessor('last_login', { header: 'Last login', cell: ({ getValue }) => `${getValue() ? format(getValue(), 'MMM dd yyyy') : ''}`, sortingFn: 'datetime' }),
  helper.accessor('sessions_sum', { header: 'Sessions'}),
  helper.accessor('sessions_days', { header: 'Session days'}),
  helper.accessor('user_status', { header: 'Status'}),
]
</script>

<template>
  <div>
    <h3 class="subtitle is-3">User performance</h3>
    <Datatable :data="data" :columns="columns" />
  </div>
</template>